const config = {
  development: {
    backendurl: process.env.REACT_APP_DEV_API_URL || 'http://localhost:4000/user/',
    botName: process.env.REACT_APP_DEV_BOT_NAME || 'testminiapp_bot',
  },
  production: {
    backendurl: process.env.REACT_APP_PROD_API_URL || 'https://api.banicoin.io/user/',
    botName: process.env.REACT_APP_PROD_BOT_NAME || 'Realbani_bot',
  },
  staging: {
    backendurl: process.env.REACT_APP_STAGING_API_URL || 'https://staging.api.banicoin.io/user/',
    botName: process.env.REACT_APP_STAGING_BOT_NAME || 'TaskRewardBot_bot',
  },
};

const env = process.env.REACT_APP_ENV || 'development';
const currentConfig = config[env];

export default currentConfig;
