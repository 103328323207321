import React, { useState, useEffect, useRef } from 'react';
import '../asset/css/Style.css';
// import Sound1 from './sounds/start.mp3';
// import Sound2 from './sounds/splatter.mp3';
import Sound1 from '../asset/sounds/start.mp3';
import Sound2 from '../asset/sounds/splatter.mp3';
import $ from 'jquery';
import { Link } from 'react-router-dom';
// import { FaCaretSquareLeft } from "react-icons/fa";
// import { Link } from 'react-router-dom';
import 'jquery-ui/ui/effects/effect-explode'; // Correct import for the explode effect
import 'jquery-ui/themes/base/theme.css'; // Import base theme for styles
import { FaCaretSquareLeft } from 'react-icons/fa';

const fruits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

function Fruitscutter() {
  const [playing, setPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [trialsLeft, setTrialsLeft] = useState(3);
  const [fruitPosition, setFruitPosition] = useState({ top: -50, left: 0 });
  const [fruitStep, setFruitStep] = useState(1);
  const [fruitVisible, setFruitVisible] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [fruitImage, setFruitImage] = useState(''); // State for fruit image
  const [showReadyMessage, setShowReadyMessage] = useState(true); // State for ready message
  const actionRef = useRef(null);
  const audioRef1 = useRef(null); // Ref for the start audio
  const audioRef2 = useRef(null); // Ref for the splatter audio

  const startGame = () => {
    setPlaying(true);
    setScore(0);
    setTrialsLeft(3);
    setGameOver(false);
    setFruitVisible(false);
    setShowReadyMessage(false); // Hide the ready message
    startAction();

    // Play start audio
    if (audioRef1.current) {
      audioRef1.current.play(); // Play the audio
    }
  };

  const startAction = () => {
    // Update the fruit image once here
    const newFruitImage = chooseRandomFruit();
    setFruitImage(newFruitImage);

    // Check the screen width and set the left position accordingly
    const screenWidth = window.innerWidth;
    const leftPosition =
      screenWidth > 991 ? Math.round(550 * Math.random()) : Math.round(150 * Math.random());

    setFruitPosition({
      top: -50,
      left: leftPosition,
    });

    setFruitStep(1 + Math.round(5 * Math.random()));
    setFruitVisible(true);

    actionRef.current = setInterval(() => {
      setFruitPosition((prev) => ({
        ...prev,
        top: prev.top + fruitStep,
      }));
    }, 10);
  };

  const stopAction = () => {
    clearInterval(actionRef.current);
    setFruitVisible(false);
  };

  const sliceFruit = () => {
    setScore((prevScore) => prevScore + 1);
    $('#fruit1').hide('explode', 400, function () {
      console.log('Fruit hidden after explode effect');
    }); // Ensure this effect works

    stopAction();

    // Play splatter sound
    if (audioRef2.current) {
      audioRef2.current.play(); // Play the splatter sound
    }

    // Restart fruit action after slicing
    setTimeout(() => {
      console.log('Restarting action');
      startAction();
    }, 500);
  };

  const chooseRandomFruit = () => {
    // Random fruit selection
    const randomFruit = fruits[Math.floor(Math.random() * fruits.length)];
    return `https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/${randomFruit}.png`;
  };

  useEffect(() => {
    if (fruitPosition.top > 500) {
      // Assuming container height is 500px
      if (trialsLeft > 1) {
        setTrialsLeft(trialsLeft - 1);
        startAction();
      } else {
        setGameOver(true);
        stopAction();
      }
    }
  }, [fruitPosition.top]);

  return (
    <div className="top-fruit-game-sec pt-5">
      <div id="container">
        {/* <Link to="/" style={{textDecoration: "none"}} className='back-btns text-white fw-bold'>
        <FaCaretSquareLeft className='square-left-icons p-1' />
      </Link> */}
        <div id="instructions">Slice Fruits</div>

        {showReadyMessage && ( // Display the ready message
          <div id="readyMessage">
            <p className="gaming-action-msg">Are you ready for the ultimate slice action?</p>
          </div>
        )}

        {gameOver && (
          <div id="gameOver">
            <p className="gaming-action-msg">Game Over!</p>
            <p className="gaming-action-msg">Your score is {score}</p>
          </div>
        )}

        <div id="fruitcontainer">
          <div id="score">
            <div className="scoreDisplay">
              <img
                src="https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/scr.png"
                alt=""
              />
              <span id="scoreValue">{score}</span>
            </div>
          </div>

          <div id="trialsleft">
            {Array.from({ length: trialsLeft }).map((_, i) => (
              <img
                key={i}
                src="https://raw.githubusercontent.com/Saumya-07/Fruit-Slicer/master/images/wrong.png"
                className="life"
                alt="life"
              />
            ))}
          </div>

          {!gameOver && fruitVisible && (
            <div
              id="fruit1"
              onMouseOver={sliceFruit}
              style={{
                position: 'absolute',
                top: `${fruitPosition.top}px`,
                left: `${fruitPosition.left}px`,
                display: fruitVisible ? 'block' : 'none',
              }}
            >
              <img src={fruitImage} alt="fruit" className="new-fruits-01" />{' '}
              {/* Use fruitImage state here */}
            </div>
          )}
        </div>
        <Link to="/">
          <FaCaretSquareLeft className="coinflipping-left-icons" style={{ color: '#d3901d' }} />
        </Link>

        <div id="startReset" onClick={startGame}>
          {playing ? 'Reset Game' : 'Start Game'}
        </div>

        <audio ref={audioRef1} id="slicesound1" src={Sound1} preload="auto"></audio>
        <audio ref={audioRef2} id="slicesound2" src={Sound2} preload="auto"></audio>
      </div>
    </div>
  );
}

export default Fruitscutter;
