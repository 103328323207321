import React from 'react';
// import { Link } from "react-router-dom";
// import { AiOutlineArrowLeft } from "react-icons/ai";
// import Coinflipping from "../asset/images/coinflipping.webp"
// import Goatlogo from "../asset/images/Goat-logo.webp"
function Flipping() {
  return (
    <></>
    //         <div className='goat-full-box-02 min-vh-100 goat-full-home'>
    //             <div className='container-fluid'>
    //                 <div className='row px-0 position-relative'>
    //                     <div className='col-12'>
    //                         <div className='py-2'>
    //                             <Link to="/">
    //                                 <AiOutlineArrowLeft className='text-white fs-4 position-absolute' />
    //                             </Link>
    //                             <p className='mb-auto  text-white text-center fw-bold'>Flip your Goats</p>

    //                         </div>
    //                     </div>
    //                 </div>
    //                 <p className='text-muted  py-3 text-center'>Bet on what you baaa-llieve in</p>
    //                 <div className='text-center'>
    //                     <img src={Coinflipping} className='coin-flipping-img' />
    //                 </div>

    //                 <div className='row goat-flipping'>
    //                     <div className='col-12'>
    //                         <div>
    //                             <div class="tab-content" id="pills-tabContent ">
    //                                 <div class="tab-pane fade show active text-center py-3" id="Flipping-pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><span className='text-muted fw-bold'>1 likes :</span><span className='fw-bold' style={{ color: "gold" }}> Heads</span></div>
    //                                 <div class="tab-pane fade text-center py-3" id="Flipping-pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><span className='text-muted fw-bold'>1 likes :</span><span className='fw-bold' style={{ color: "gold" }}> Tails</span></div>

    //                             </div>
    //                             <ul class="nav nav-pills flipping-nav-pills bg-dark py-0 mb-3" id="pills-tab" role="tablist">
    //                                 <li class="nav-item flipping-nav-items " role="presentation">
    //                                     <button class="nav-link active flipping-nav-items-btn py-3 " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#Flipping-pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Heads</button>
    //                                 </li>
    //                                 <li class="nav-item flipping-nav-items " role="presentation">
    //                                     <button class="nav-link flipping-nav-items-btn py-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#Flipping-pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Tails</button>
    //                                 </li>

    //                             </ul>

    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className='row text-center second-goat-flipping'>
    //                     <div class="tab-content d-flex justify-content-center align-items-center" id="pills-tabContent">

    //                         <div class="tab-pane fade show active text-white my-2" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">for<span className='tn-coins fw-bold '> 1000 </span> $Goats</div>
    //                         <div class="tab-pane fade text-white my-2" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">for<span className='tn-coins fw-bold '> 2000 </span> $Goats</div>
    //                         <div class="tab-pane fade text-white my-2" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">for<span className='tn-coins fw-bold '> 5000 </span> $Goats</div>

    //                         <div class="tab-pane fade text-white my-2" id="pills-h2-1" role="tabpanel" aria-labelledby="pills-h2-tab1">for<span className='tn-coins fw-bold text-white'> 10,000 </span> $Goats</div>
    //                         <div class="tab-pane fade text-white my-2" id="pills-h2-2" role="tabpanel" aria-labelledby="pills-h2-tab2">for<span className='tn-coins fw-bold text-white'> 50,000 </span> $Goats</div>
    //                         <div class="tab-pane fade text-white my-2" id="pills-h2-3" role="tabpanel" aria-labelledby="pills-h2-tab2">for<span className='tn-coins fw-bold text-white'> 100,000 </span> $Goats</div>
    //                         {/* <div class="tab-pane fade text-white my-2" id="pills-h2-3" role="tabpanel" aria-labelledby="pills-h2-tab3">for<span className='tn-coins fw-bold text-white'> 100,000 </span> $Goats</div> */}
    //                     </div>
    //                     <ul class="nav nav-pills second-flipping-pills mb-3 row mx-auto px-0 bg-dark" id="pills-tab" role="tablist">

    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link active w-100 py-2" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>1,000</span></button>
    //                         </li>
    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link w-100 py-2" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>2,000</span></button>
    //                         </li>
    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link w-100 py-2" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>5,000</span></button>
    //                         </li>

    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link w-100 py-2" id="pills-h2-tab1" data-bs-toggle="pill" data-bs-target="#pills-h2-1" type="button" role="tab" aria-controls="pills-h2-1" aria-selected="false"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>10,000</span></button>
    //                         </li>
    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link w-100 py-2" id="pills-h2-tab2" data-bs-toggle="pill" data-bs-target="#pills-h2-2" type="button" role="tab" aria-controls="pills-h2-2" aria-selected="false"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>50,000</span></button>
    //                         </li>
    //                         <li class="nav-item col-4  px-0" role="presentation">
    //                             <button class="nav-link w-100 py-2" id="pills-h2-tab3" data-bs-toggle="pill" data-bs-target="#pills-h2-3" type="button" role="tab" aria-controls="pills-h2-3" aria-selected="false"><span>
    //                                 <img src={Goatlogo} className='thoushand-img-logo' /></span><br /><span className='text-white'>100,000</span></button>
    //                         </li>
    //                     </ul>
    //                 <p className='my-3 fw-bold' style={{color:"rgba(255,69,58,1)"}}>You don’t have enough $GOATS</p>

    //                 </div>
    //                 <div className='my-3'>
    //                 <button className='text-white w-100 py-3 border-0 bg-dark text-dark my-2'style={{borderRadius:"10px"}}>Flip</button>
    //                 </div>

    //             </div>

    //         </div>
  );
}

export default Flipping;
