import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { ImNotification } from 'react-icons/im';
import { makeApiRequest } from '../services/commonAxiosService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Game2048() {
  const [board, setBoard] = useState([
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameCount, setGameCount] = useState(false);
  const [gamePlayed, setGamePlayed] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [canPlay, setCanPlay] = useState(true);

  let userId = localStorage.getItem('userId') != undefined ? localStorage.getItem('userId') : '';

  useEffect(() => {
    getGameCount();
    const savedHighScore = localStorage.getItem('2048HighScore');
    if (savedHighScore) {
      setHighScore(parseInt(savedHighScore));
    }
  }, []);

  const showToast = () => {
    toast.info(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ color: 'grey' }}>You can participate/play 5 times in a day!</span>
      </div>,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: { backgroundColor: 'white', color: 'black' },
      }
    );
  };

  const getGameCount = async () => {
    try {
      let payLoad = {
        gameType: 1,
        chatId: userId,
      };
      let data = {
        url: 'get-game-count',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(data);
      if (response.status) {
        setGameCount(true);
        setGamePlayed(response.game1);
        setCanPlay(response.game1 < 5);
      } else {
        setGameCount(false);
        setGamePlayed(response.game1);
        setCanPlay(false);
      }
    } catch (error) {
      console.log('getGameCount err', error);
      setCanPlay(false);
    }
  };

  const handleGameEnd = async () => {
    if (!isGameStarted || !canPlay) return;

    setGameOver(true);
    setIsGameStarted(false);

    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('2048HighScore', score);
    }

    try {
      let payLoad = {
        chatId: userId,
        gameType: 1,
        gameScore: score,
      };
      let data = {
        url: 'game-count',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(data);
      if (response.status) {
        toast.success(`Game Over! Score: ${score}`, { theme: 'dark' });
      } else {
        toast.error('Failed to submit score', { theme: 'dark' });
      }
      getGameCount();
    } catch (error) {
      console.log('handleGameEnd error', error);
      toast.error('Error submitting score', { theme: 'dark' });
    }
  };

  const initializeBoard = () => {
    if (!canPlay) {
      toast.error('No more plays available today', { theme: 'dark' });
      return;
    }

    let newBoard = Array(4)
      .fill(0)
      .map(() => Array(4).fill(0));
    addNewTile(newBoard);
    addNewTile(newBoard);
    setBoard(newBoard);
    setScore(0);
    setGameOver(false);
    setIsGameStarted(true);
  };

  const addNewTile = (board) => {
    const emptyTiles = [];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (board[i][j] === 0) {
          emptyTiles.push({ x: i, y: j });
        }
      }
    }
    if (emptyTiles.length > 0) {
      const { x, y } = emptyTiles[Math.floor(Math.random() * emptyTiles.length)];
      board[x][y] = Math.random() < 0.9 ? 2 : 4;
    }
  };

  const checkGameOver = (board) => {
    // Check for 2048 tile or win condition
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (board[i][j] >= 2048) {
          handleGameEnd();
          return true;
        }
      }
    }

    // Check for empty spaces
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (board[i][j] === 0) return false;
      }
    }

    // Check for possible moves horizontally
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 3; j++) {
        if (board[i][j] === board[i][j + 1]) return false;
      }
    }

    // Check for possible moves vertically
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 4; j++) {
        if (board[i][j] === board[i + 1][j]) return false;
      }
    }

    // No moves left
    handleGameEnd();
    return true;
  };

  const moveLeft = useCallback(() => {
    if (gameOver || !isGameStarted || !canPlay) return;

    let newBoard = board.map((row) => [...row]);
    let changed = false;
    let newScore = score;

    for (let i = 0; i < 4; i++) {
      let column = 0;
      for (let j = 1; j < 4; j++) {
        if (newBoard[i][j] !== 0) {
          let current = j;
          while (current > column) {
            if (newBoard[i][current - 1] === 0) {
              newBoard[i][current - 1] = newBoard[i][current];
              newBoard[i][current] = 0;
              current--;
              changed = true;
            } else if (newBoard[i][current - 1] === newBoard[i][current]) {
              newBoard[i][current - 1] *= 2;
              newScore += newBoard[i][current - 1];
              newBoard[i][current] = 0;
              column = current;
              changed = true;
              break;
            } else {
              break;
            }
          }
        }
      }
    }

    if (changed) {
      addNewTile(newBoard);
      setBoard(newBoard);
      setScore(newScore);
      checkGameOver(newBoard);
    }
  }, [board, score, gameOver, isGameStarted, canPlay]);

  const moveRight = useCallback(() => {
    if (gameOver || !isGameStarted || !canPlay) return;

    let newBoard = board.map((row) => [...row]);
    let changed = false;
    let newScore = score;

    for (let i = 0; i < 4; i++) {
      let column = 3;
      for (let j = 2; j >= 0; j--) {
        if (newBoard[i][j] !== 0) {
          let current = j;
          while (current < column) {
            if (newBoard[i][current + 1] === 0) {
              newBoard[i][current + 1] = newBoard[i][current];
              newBoard[i][current] = 0;
              current++;
              changed = true;
            } else if (newBoard[i][current + 1] === newBoard[i][current]) {
              newBoard[i][current + 1] *= 2;
              newScore += newBoard[i][current + 1];
              newBoard[i][current] = 0;
              column = current;
              changed = true;
              break;
            } else {
              break;
            }
          }
        }
      }
    }

    if (changed) {
      addNewTile(newBoard);
      setBoard(newBoard);
      setScore(newScore);
      checkGameOver(newBoard);
    }
  }, [board, score, gameOver, isGameStarted, canPlay]);

  const moveUp = useCallback(() => {
    if (gameOver || !isGameStarted || !canPlay) return;

    let newBoard = board.map((row) => [...row]);
    let changed = false;
    let newScore = score;

    for (let j = 0; j < 4; j++) {
      let row = 0;
      for (let i = 1; i < 4; i++) {
        if (newBoard[i][j] !== 0) {
          let current = i;
          while (current > row) {
            if (newBoard[current - 1][j] === 0) {
              newBoard[current - 1][j] = newBoard[current][j];
              newBoard[current][j] = 0;
              current--;
              changed = true;
            } else if (newBoard[current - 1][j] === newBoard[current][j]) {
              newBoard[current - 1][j] *= 2;
              newScore += newBoard[current - 1][j];
              newBoard[current][j] = 0;
              row = current;
              changed = true;
              break;
            } else {
              break;
            }
          }
        }
      }
    }

    if (changed) {
      addNewTile(newBoard);
      setBoard(newBoard);
      setScore(newScore);
      checkGameOver(newBoard);
    }
  }, [board, score, gameOver, isGameStarted, canPlay]);

  const moveDown = useCallback(() => {
    if (gameOver || !isGameStarted || !canPlay) return;

    let newBoard = board.map((row) => [...row]);
    let changed = false;
    let newScore = score;

    for (let j = 0; j < 4; j++) {
      let row = 3;
      for (let i = 2; i >= 0; i--) {
        if (newBoard[i][j] !== 0) {
          let current = i;
          while (current < row) {
            if (newBoard[current + 1][j] === 0) {
              newBoard[current + 1][j] = newBoard[current][j];
              newBoard[current][j] = 0;
              current++;
              changed = true;
            } else if (newBoard[current + 1][j] === newBoard[current][j]) {
              newBoard[current + 1][j] *= 2;
              newScore += newBoard[current + 1][j];
              newBoard[current][j] = 0;
              row = current;
              changed = true;
              break;
            } else {
              break;
            }
          }
        }
      }
    }

    if (changed) {
      addNewTile(newBoard);
      setBoard(newBoard);
      setScore(newScore);
      checkGameOver(newBoard);
    }
  }, [board, score, gameOver, isGameStarted, canPlay]);

  const handleKeyPress = useCallback(
    (event) => {
      if (!isGameStarted || gameOver || !canPlay) return;

      switch (event.keyCode) {
        case 37: // Left
          moveLeft();
          break;
        case 38: // Up
          moveUp();
          break;
        case 39: // Right
          moveRight();
          break;
        case 40: // Down
          moveDown();
          break;
        default:
          break;
      }
    },
    [moveLeft, moveRight, moveUp, moveDown, isGameStarted, gameOver, canPlay]
  );

  // Touch handling
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    if (!isGameStarted || gameOver || !canPlay) return;
    setTouchEnd(null);
    setTouchStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
  };

  const handleTouchMove = (e) => {
    if (!isGameStarted || gameOver || !canPlay) return;
    setTouchEnd({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
  };

  const handleTouchEnd = () => {
    if (!isGameStarted || gameOver || !canPlay || !touchStart || !touchEnd) return;

    const xDiff = touchStart.x - touchEnd.x;
    const yDiff = touchStart.y - touchEnd.y;
    const minSwipeDistance = 50;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (Math.abs(xDiff) > minSwipeDistance) {
        if (xDiff > 0) {
          moveLeft();
        } else {
          moveRight();
        }
      }
    } else {
      if (Math.abs(yDiff) > minSwipeDistance) {
        if (yDiff > 0) {
          moveUp();
        } else {
          moveDown();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="min-vh-100 goat-full-box-02" style={{ paddingBottom: '120px' }}>
      <div className="container">
        <div className="d-flex align-items-center py-3">
          <Link to="/slotspage" className="me-auto">
            <AiOutlineArrowLeft className="text-white fs-4" />
          </Link>
          <span className="text-white text-center mx-auto">2048 Bani</span>
          <div className="ms-auto">
            <ImNotification
              className="text-white fs-4"
              onClick={showToast}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>

        <div className="game-status text-white text-center mb-4">
          <div className="score">Score: {score}</div>
        </div>

        {!isGameStarted && canPlay && !gameOver && (
          <div className="text-center mb-4">
            <button className="bani-new-play-btn" onClick={initializeBoard}>
              Start Game
            </button>
          </div>
        )}

        {isGameStarted && (
          <div
            className="game-board"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {board.map((row, i) => (
              <div key={i} className="row g-2">
                {row.map((cell, j) => (
                  <div key={`${i}-${j}`} className={`cell value-${cell}`}>
                    {cell !== 0 ? cell : ''}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        {gameOver && canPlay && (
          <button className="bani-new-play-btn mt-4 w-100" onClick={initializeBoard}>
            Play Again
          </button>
        )}

        {!canPlay && (
          <div className="text-center text-white mt-4">
            <div className="max-limit-01">You've reached the maximum plays for today</div>
          </div>
        )}

        {isGameStarted && !gameOver && (
          <div className="text-center text-white mt-4">
            <p className="text-muted">Use arrow keys or swipe to move tiles</p>
          </div>
        )}

        <ToastContainer />
      </div>
    </div>
  );
}

export default Game2048;
