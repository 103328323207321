import React, { useState, useEffect } from 'react';
import '../App.css';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { ImNotification } from 'react-icons/im';
import useAds from '../hooks/useAds';
import { Link } from 'react-router-dom';
import Pin from '../asset/images/BANI.png';
import { makeApiRequest } from '../services/commonAxiosService';
import { ToastContainer, toast } from 'react-toastify';

function Jackpotpage({ data }) {
  const [timer, setTimer] = useState({});

  let userChatId;
  if (data) {
    userChatId = data;
  } else {
    userChatId = localStorage.getItem('userId');
  }
  const [activeDay, setActiveDay] = useState(1);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getTaskStatus();
  }, []);

  const showToast = () => {
    toast.info(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ color: 'grey' }}>
          Claim your daily rewards twice a day – available at 12:00 UTC!
        </span>
      </div>,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: { backgroundColor: 'white', color: 'black' },
      }
    );
  };

  const { showAd } = useAds();

  const handleAdClick = async () => {
    try {
      await showAd('099f788d-3141-448d-b303-1beae7ff07ed', async () => {
        try {
          if (userData.dailyTaskStatus == 1) {
            return toast.error('You have already claimed your reward', { theme: 'dark' });
          }
          let payLoad = {
            chatId: userChatId,
            dayCount: 1,
            taskReward: 500,
          };
          let params = {
            url: 'post-daily-task',
            method: 'post',
            data: payLoad,
          };
          let res = await makeApiRequest(params);
          if (res.status) {
            toast.success(res.message, { theme: 'dark' });
            window.open(
              'https://play.google.com/store/apps/details?id=com.kerdos.wallet&hl=en',
              '_blank'
            );
            getTaskStatus();
          } else {
            toast.error(res.message, { theme: 'dark' });
          }
        } catch (error) {
          toast.error('Something went wrong', { theme: 'dark' });
        }
        setActiveDay(1);
      });
    } catch (error) {
      toast.error('Failed to load advertisement. Please try again.', { theme: 'dark' });
      console.error('Ad error:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const expiryTime = () => {
        try {
          getTaskStatus();
          const endDate = new Date(userData.userEndDate);

          const now = new Date();
          const remainingMs = endDate - now;

          const countdownSeconds = Math.floor(remainingMs / 1000);
          const hours = Math.floor(countdownSeconds / 3600);
          const minutes = Math.floor((countdownSeconds % 3600) / 60);
          const seconds = countdownSeconds % 60;

          const countdown = {
            hours: Math.max(hours, 0),
            minutes: Math.max(minutes, 0),
            seconds: Math.max(seconds, 0),
          };

          setTimer(countdown);
          if (remainingMs <= 0) clearInterval(intervalId);
        } catch (error) {
          console.error('Error calculating expiry time:', error);
          clearInterval(intervalId);
        }
      };

      expiryTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userData]);

  const getTaskStatus = async () => {
    try {
      let payload = {
        userChatId: userChatId,
      };
      let params = {
        url: 'get-user-data',
        method: 'post',
        data: payload,
      };
      let res = await makeApiRequest(params);
      if (res.status) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log(error, 'getTaskStatus error');
    }
  };

  return (
    <div className="min-vh-100 goat-full-box-02 friends-zone jackpot-new-modals-sec position-relative">
      <div className="container jackpot-modal-container">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="d-flex justify-content-between bani-close-items align-items-center py-2"></div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <span className="text-primary ps-2" style={{ fontSize: '20px' }}>
                  <Link to="/">
                    <AiOutlineArrowLeft className="text-white fs-4" />
                  </Link>
                </span>
                <span className="text-primary pe-2 text-center">
                  <ImNotification
                    className="text-white fs-4 jackpot-page-notification"
                    onClick={showToast}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
                <ToastContainer />
                <div>
                  <div></div>
                </div>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 points-section">
        <p className="text-white fw-bold text-center total-claimed">Total Claimed</p>
        <h5 className="text-center fw-bold my-2 fw-bold total-claimed-points">
          {' '}
          {userData.dailyClaim || 0}{' '}
        </h5>
      </div>

      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-8 pe-0 day-one-bani">
            <div className="">
              <a onClick={handleAdClick}>
                <button
                  className={`w-100 border-0 fw-bold text-white top-first-left-btn ${activeDay === 1 ? 'active-btn' : ''}`}
                >
                  Daily Rewards
                </button>
                <div
                  className={`py-3 text-center box-def-01 ${activeDay === 1 ? 'active-image' : ''}`}
                >
                  <a></a> <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  {userData && userData.dailyTaskStatus == 1 ? (
                    <p className="mb-auto mt-3 table-bin-points-001 py-1">Completed</p>
                  ) : (
                    <p className="mb-auto mt-3 table-bin-points py-1">+500 $BANI</p>
                  )}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {timer.hours || timer.minutes || timer.seconds ? (
        <div className="timer text-white text-center my-3 ps-3 d-flex justify-content-center">
          <p className="timer-button jackpot-rewards-hours me-1">
            {timer.hours || '00'}
            <br />
            <span className="jackpot-rewards-hours-01">Hours</span>
          </p>
          <p className="timer-button jackpot-rewards-hours me-1">
            {timer.minutes || '00'}
            <br />
            <span className="jackpot-rewards-hours-01">Minutes</span>
          </p>
          <p className="timer-button jackpot-rewards-hours">
            {timer.seconds || '00'}
            <br />
            <span className="jackpot-rewards-hours-01">Seconds</span>
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Jackpotpage;
