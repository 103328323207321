import React from 'react';
// import Footer from './Footer';
import Navtabs from './Navtabs';
function Secondtab({ data }) {
  return (
    <div className="goat-secondtabs-00">
      <div className="container">
        <div className="row min-vh-100">
          <div className="col-lg-12">
            <div className="">
              <h2 className="text-white pt-4 pb-2 text-center" style={{ fontSize: '45px' }}>
                Ranking
              </h2>
              <Navtabs />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Secondtab;
