import React from 'react';
// import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
// import { RiHome6Line } from "react-icons/ri";
import '../App.css';

function Footer() {
  const location = useLocation();
  return (
    <div className="goat-tot-footer-ctn">
      <div>
        <ul className="goat-footer-full-sec px-0 mt-4">
          <li className="text-center">
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              <svg
                viewBox="0 0 25 24"
                focusable="false"
                style={{ color: location.pathname === '/mission' ? '#0d6efd' : 'grey' }}
                className={`chakra-icon ${location.pathname === '/' ? 'goat-footer-icons' : 'goat-footer-icons-01'}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.3287 3.8032L20.3308 7.49793C21.2948 8.1956 21.8684 9.31112 21.875 10.5011V17.1895C21.813 19.3342 20.0316 21.0268 17.8866 20.979H7.87289C5.7242 21.032 3.93695 19.338 3.875 17.1895V10.5011C3.88159 9.31112 4.45519 8.1956 5.41921 7.49793L10.4213 3.8032C11.8818 2.73227 13.8682 2.73227 15.3287 3.8032ZM8.61184 16.9716H17.1382C17.5306 16.9716 17.8487 16.6535 17.8487 16.2611C17.8487 15.8687 17.5306 15.5506 17.1382 15.5506H8.61184C8.21943 15.5506 7.90132 15.8687 7.90132 16.2611C7.90132 16.6535 8.21943 16.9716 8.61184 16.9716Z"
                ></path>
              </svg>
              <br />
              <span
                className="fw-bold"
                style={{ color: location.pathname === '/' ? '#0d6efd' : 'grey' }}
              >
                Home
              </span>
            </NavLink>
          </li>
          <li className="text-center">
            <NavLink to="/ranking" style={{ textDecoration: 'none' }}>
              <svg
                viewBox="0 0 25 24"
                focusable="false"
                className={`chakra-icon ${location.pathname === '/ranking' ? 'goat-footer-icons' : 'goat-footer-icons-01'}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.43452 2H18.8155C20.9194 2 22.625 3.70558 22.625 5.80952V18.1905C22.625 20.2944 20.9194 22 18.8155 22H6.43452C4.33058 22 2.625 20.2944 2.625 18.1905V5.80952C2.625 3.70558 4.33058 2 6.43452 2ZM17.8631 13.1905C18.2576 13.1905 18.5774 12.8707 18.5774 12.4762V9.58095C18.5723 9.18861 18.2554 8.8718 17.8631 8.86667H15.006C14.6115 8.86667 14.2917 9.18646 14.2917 9.58095C14.2917 9.97544 14.6115 10.2952 15.006 10.2952H15.8536L12.4631 13.6857L10.5583 11.781C10.0529 11.2772 9.23522 11.2772 8.72976 11.781L6.28214 14.2286C6.00362 14.5074 6.00362 14.9592 6.28214 15.2381C6.41537 15.373 6.59732 15.4485 6.7869 15.4476C6.97671 15.4496 7.15907 15.3739 7.29167 15.2381L9.68214 12.9524L11.5869 14.8571C12.0924 15.3609 12.91 15.3609 13.4155 14.8571L17.1488 11.0952V12.4762C17.1488 12.8707 17.4686 13.1905 17.8631 13.1905Z"
                ></path>
              </svg>
              <br />
              <span
                className="fw-bold"
                style={{ color: location.pathname === '/ranking' ? '#0d6efd' : 'grey' }}
              >
                Ranking
              </span>
            </NavLink>
          </li>
          <li className="text-center">
            <NavLink to="/mission" style={{ textDecoration: 'none' }}>
              <svg
                viewBox="0 0 25 24"
                focusable="false"
                className={`chakra-icon ${location.pathname === '/mission' ? 'goat-footer-icons' : 'goat-footer-icons-01'}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.4261 2L11.0561 4.8C10.0771 6.80035 8.76508 8.61962 7.17608 10.18L6.99608 10.35C5.97554 11.3408 5.39244 12.6977 5.37608 14.12V14.3C5.34905 17.0851 7.00868 19.6101 9.57608 20.69L9.83608 20.8C11.5199 21.5152 13.4222 21.5152 15.1061 20.8H15.1661C17.7527 19.6762 19.4123 17.1099 19.3761 14.29V9.95C18.5141 11.9185 16.9487 13.4946 14.9861 14.37C14.9861 14.37 14.9861 14.37 14.9261 14.37C14.8661 14.37 14.1661 14.66 13.8661 14.37C13.5981 14.0989 13.5725 13.6712 13.8061 13.37L13.8761 13.32H13.9261C16.2218 11.575 16.7571 8.34172 15.1461 5.95C13.8461 3.97 12.4261 2 12.4261 2Z"></path>
              </svg>
              <br />
              <span
                className="fw-bold"
                style={{ color: location.pathname === '/mission' ? '#0d6efd' : 'grey' }}
              >
                {' '}
                Mission
              </span>
            </NavLink>
          </li>
          <li className="text-center">
            <NavLink to="/friends" style={{ textDecoration: 'none' }}>
              <svg
                viewBox="0 0 25 24"
                focusable="false"
                className={`chakra-icon ${location.pathname === '/friends' ? 'goat-footer-icons' : 'goat-footer-icons-01'}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.045 12H9.125C11.3341 12 13.125 10.2091 13.125 7.99996V6.42996C13.1224 5.53269 12.762 4.67352 12.1238 4.04282C11.4856 3.41212 10.6222 3.06197 9.725 3.06996H8.405C6.5516 3.07545 5.05049 4.57656 5.045 6.42996V7.99996C5.045 10.2091 6.83586 12 9.045 12Z"></path>
                <path d="M17.625 11.25C19.2819 11.25 20.625 9.90681 20.625 8.24996V7.09996C20.625 5.71597 19.5089 4.59094 18.125 4.57996H17.125C15.7411 4.59094 14.625 5.71597 14.625 7.09996V8.24996C14.625 9.90681 15.9681 11.25 17.625 11.25Z"></path>
                <path d="M22.515 15.42L22.355 14.63C22.1659 13.5455 21.2259 12.753 20.125 12.75H14.025C13.2795 12.7515 12.5847 13.1272 12.175 13.75H5.125C4.41768 13.7505 3.73755 14.0225 3.225 14.51C2.78463 14.9135 2.47473 15.4392 2.335 16.02C2.335 16.07 2.335 16.12 2.335 16.16V16.42L2.125 17.22C1.91111 18.0952 2.10191 19.0202 2.64467 19.7394C3.18743 20.4585 4.02467 20.8956 4.925 20.93H13.205C13.4175 20.9292 13.6291 20.9024 13.835 20.85H13.965C14.1153 20.8071 14.2623 20.7537 14.405 20.69L14.565 20.6C14.6844 20.5326 14.7981 20.4557 14.905 20.37L15.065 20.24C15.1634 20.1483 15.2568 20.0515 15.345 19.95C15.345 19.9 15.435 19.86 15.475 19.8C15.5868 19.6531 15.6839 19.4957 15.765 19.33C15.8077 19.2391 15.8444 19.1456 15.875 19.05C15.9143 18.9589 15.9477 18.8653 15.975 18.77C15.975 18.67 16.025 18.57 16.045 18.47V18.32H20.325C21.0312 18.2991 21.6897 17.9589 22.1154 17.3951C22.5412 16.8314 22.6881 16.1049 22.515 15.42Z"></path>
              </svg>
              <br />
              <span
                className="fw-bold"
                style={{ color: location.pathname === '/friends' ? '#0d6efd' : 'grey' }}
              >
                {' '}
                Friends
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
