import { useEffect, useCallback, useState } from 'react';

const useAds = () => {
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [currentAdId, setCurrentAdId] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onAdClosed, setOnAdClosed] = useState(null);

  useEffect(() => {
    // Ensure AdsManager is available
    if (typeof window.AdsManager === 'undefined') {
      console.error('Ads SDK not loaded');
      return;
    }

    // Override the default hide method to update our state
    const originalHideAd = window.AdsManager.hideAd;
    window.AdsManager.hideAd = () => {
      originalHideAd.call(window.AdsManager);
      setIsAdVisible(false);
      setCurrentAdId(null);
      if (onAdClosed) {
        onAdClosed();
      }
    };
  }, [onAdClosed]);

  const showAd = useCallback(async (blockId, onClosedCallback) => {
    if (typeof window.AdsManager === 'undefined') {
      setError('Ads SDK not loaded');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      setOnAdClosed(() => onClosedCallback);

      // Show the ad
      await window.AdsManager.showAd(blockId);

      setIsAdVisible(true);
      setCurrentAdId(blockId);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const hideAd = useCallback(() => {
    if (typeof window.AdsManager !== 'undefined') {
      window.AdsManager.hideAd();
    }
  }, []);

  return {
    showAd,
    hideAd,
    isAdVisible,
    currentAdId,
    error,
    isLoading,
  };
};

export default useAds;
