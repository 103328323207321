import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { ImNotification } from 'react-icons/im';
// import Goatlogo from "../asset/images/Goat-logo.webp"
import Tokeone from '../asset/images/tokenone.gif';
import Freecalm from '../asset/images/free-claim.gif';
// import Ticketlogo from "../asset/images/tickets.webp"
import { MdKeyboardArrowRight } from 'react-icons/md';
// import Grandprize from "../asset/images/grandprize.webp"
import { GrLock } from 'react-icons/gr';
import { Link } from 'react-router-dom';
function Previousnumber() {
  return (
    <div className="whole-get-more min-vh-100 px-1">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center py-3">
              <Link to="/">
                <AiOutlineArrowLeft className="text-white fs-4" />
              </Link>
              <p className="mb-auto  text-white fw-bold">Get more $GOATS</p>
              <ImNotification className="text-white fs-4" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 text-center my-3">
            <div>
              <button className="get-more-timer me-1 fw-bold">
                12
                <br />
                Hours
              </button>
              <button className="get-more-timer me-1 fw-bold">
                22
                <br />
                Mins
              </button>
              <button className="get-more-timer fw-bold">
                34
                <br />
                secs
              </button>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center position-relative">
          <div className="col-5 px-0 mt-3">
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+10,000</span>
                {/* <span><img src={Goatlogo} className="get-more-goats me-1" /></span> */}
                <span className="text-white">+2</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." />
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 text-white fw-bold"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a href="#" class="btn btn-primary my-1">
                      0.1$TON
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5  px-0 mt-3">
            <div className=" ">
              <div className="d-flex  align-items-center whl-price-box justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+10,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  <img src={Freecalm} class="card-img-top transaction-rounded-img " alt="..." />
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 fw-bold"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free calm
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
          <div className="keyboard-arrows-sec d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
        {/* <===========> */}
        <div className="row d-flex justify-content-between align-items-center mt-5 pt-4 position-relative moderate">
          <div className="col-5 px-0 mt-3" style={{ zIndex: 2 }}>
            <div className=" bg-primary goat-prices-box" style={{ zIndex: 2 }}>
              <div className="d-flex align-items-center whl-price-box  justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">25,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 mt-0 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 fw-bold"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free claim
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5  px-0 mt-3" style={{ zIndex: 2 }}>
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+10,000</span>
                {/* <span className='ms-1'><img src={Ticketlogo} className="get-more-goats" /></span> */}
                <span className="text-white">+2</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 fw-bold text-white"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            {/* <p className='ps-0'><MdKeyboardArrowRight className='keyboard-arrow-right fs-5'/></p> */}
          </div>
          <div className="keyboard-arrows-sec d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
        {/* <========> */}
        <div className="row d-flex justify-content-between align-items-center mt-5 pt-4 position-relative">
          <div className="col-5  px-0 mt-3">
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+10,000</span>
                {/* <span><img src={Goatlogo} className="get-more-goats me-1" /></span> */}
                <span className="text-white">+2</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body" style={{ zIndex: 2 }}>
                    <h5
                      class="card-title transaction-heading my-2 text-white fw-bold"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 px-0 mt-3">
            <div className=" bg-primary goat-prices-box">
              <div className="d-flex align-items-center whl-price-box  justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">25,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 mt-0 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2  fw-bold"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free claim
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            {/* <p className='ps-0'><MdKeyboardArrowRight className='keyboard-arrow-right fs-5'/></p> */}
          </div>
          <div className="keyboard-arrows-sec d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center mt-5 pt-4 position-relative moderate">
          <div className="col-5 px-0 mt-3" style={{ zIndex: 2 }}>
            <div className=" bg-primary goat-prices-box">
              <div className="d-flex align-items-center whl-price-box  justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0  mb-1 me-1" /></span> */}
                <span className="text-white">+100,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 mt-0 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free claim
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5  px-0 mt-3" style={{ zIndex: 2 }}>
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+10,000</span>
                {/* <span className='ms-1'><img src={Ticketlogo} className="get-more-goats" /></span> */}
                <span className="text-white">+2</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 text-white"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            {/* <p className='ps-0'><MdKeyboardArrowRight className='keyboard-arrow-right fs-5'/></p> */}
          </div>
          <div className="keyboard-arrows-sec d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center mt-5 pt-4 position-relative">
          <div className="col-5  px-0 mt-3" style={{ zIndex: 2 }}>
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+160,000</span>
                {/* <span className='ms-1'><img src={Ticketlogo} className="get-more-goats" /></span> */}
                <span className="text-white">+15</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 text-white"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 px-0 mt-3" style={{ zIndex: 2 }}>
            <div className=" bg-primary goat-prices-box">
              <div className="d-flex align-items-center whl-price-box  justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0  mb-1 me-1" /></span> */}
                <span className="text-white">+160,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 mt-0 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free claim
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            {/* <p className='ps-0'><MdKeyboardArrowRight className='keyboard-arrow-right fs-5'/></p> */}
          </div>
          <div className="keyboard-arrows-sec d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center mt-5 pt-4 position-relative">
          <div className="col-5 px-0 mt-3" style={{ zIndex: 2 }}>
            <div className=" bg-primary goat-prices-box">
              <div className="d-flex align-items-center whl-price-box  justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0  mb-1 me-1" /></span> */}
                <span className="text-white">+250,000</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 mt-0 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2"
                      style={{ color: 'rgba(48,209,88,1)' }}
                    >
                      Free claim
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5  px-0 mt-3" style={{ zIndex: 2 }}>
            <div className="bg-primary goat-prices-box">
              <div className="d-flex align-items-center justify-content-center py-2">
                {/* <span className='px-0'><img src={Goatlogo} className="get-more-goats px-0 me-1" /></span> */}
                <span className="text-white">+250,000</span>
                {/* <span className='ms-1'><img src={Ticketlogo} className="get-more-goats" /></span> */}
                <span className="text-white">+20</span>
              </div>
              <div>
                <div class="card text-center goat-prices-box-01 bg-dark" style={{ zIndex: 2 }}>
                  {/* <img src={Tokeone} class="card-img-top transaction-rounded-img " alt="..." /> */}
                  <div class="card-body">
                    <h5
                      class="card-title transaction-heading my-2 text-white"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Make a transaction
                    </h5>
                    <a
                      href="#"
                      class="btn btn-primary my-1 w-100 text-muted d-flex justify-content-between align-items-center fw-bold"
                      style={{ backgroundColor: '#000000', border: 'none', borderRadius: '10px' }}
                    >
                      clalm
                      <GrLock />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="keyboard-arrows d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            {/* <p className='ps-0'><MdKeyboardArrowRight className='keyboard-arrow-right fs-5'/></p> */}
          </div>
        </div>

        <div className="row mt-5 pt-5 pb-5 position-relative">
          <div className="col-12 px-0 ">
            <div>
              <div class="card bg-dark">
                {/* <img src={Grandprize} class="card-img-top" alt="..."/> */}
                <div class="card-body">
                  <h6 class="card-title text-white fw-bold text-center py-3">Make a transaction</h6>

                  <a
                    href="#"
                    class="btn text-muted w-100 mb-5 go-lock-btn py-3 fw-bold d-flex justify-content-between align-items-center py-1"
                    style={{ backgroundColor: '#000000' }}
                  >
                    Go
                    <GrLock className="fw-bold" />
                  </a>
                  <a
                    href="#"
                    class="btn text-muted w-100 go-lock-btn fw-bold d-flex justify-content-between align-items-center py-3"
                    style={{ backgroundColor: '#000000' }}
                  >
                    Go
                    <GrLock className="fw-bold" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="keyboard-arrows-001 d-flex align-items-center justify-content-center mt-2">
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
            <p className="mb-auto">
              <MdKeyboardArrowRight className="keyboard-arrow-right fs-5" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Previousnumber;
