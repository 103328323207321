import React, { useEffect, useState } from 'react';
// import Footer from './Footer';
import { IoCopy } from 'react-icons/io5';
import { IoShareSocial } from 'react-icons/io5';
import { makeApiRequest } from '../services/commonAxiosService';
import Handshake from '../asset/images/handshake.gif';
import config from '../config/commonConfig';
function Fourthtab({ data }) {
  let chatId;
  if (data) {
    chatId = data;
  } else {
    chatId = localStorage.getItem('userId');
  }
  let botName = config.botName;
  const [bttnText, setBttnText] = useState('Copy Link');
  const [refUsers, setRefUsers] = useState([]);
  useEffect(() => {
    getRefUsers();
  }, []);

  const copyCode = async () => {
    try {
      // Use the Clipboard API
      await navigator.clipboard.writeText(`https://t.me/${botName}?start=${chatId}`);
      setBttnText('Copied');

      // Reset button text after 3 seconds
      setTimeout(() => {
        setBttnText('Copy Link');
      }, 3000);
    } catch (err) {
      // Fallback if Clipboard API fails
      fallbackCopyCode();
    }
  };
  const handleShare = async () => {
    if (window.Telegram.WebApp) {
      try {
        let link = `https://t.me/${botName}?start=${chatId}`;
        let text =
          'Earn-to-Play, completing daily challenges, inviting friends and unlocking in-game rewards. New features and games will be added every week.';
        let referralLink = `https://t.me/share/url?url=${link}&text=${text}`;
        window.Telegram.WebApp.openTelegramLink(referralLink);
      } catch (error) {
        console.log('Error sharing:', error);
      }
    }
  };

  const fallbackCopyCode = () => {
    // Create a temporary textarea element for the fallback
    const textArea = document.createElement('textarea');
    textArea.value = `https://t.me/${botName}?start=${chatId}`;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy'); // Fallback method
      setBttnText('Copied');

      setTimeout(() => {
        setBttnText('Copy Link');
      }, 3000);
    } catch (err) {
      console.log('Fallback copy failed: ', err);
    }

    document.body.removeChild(textArea);
  };

  const getRefUsers = async () => {
    try {
      let payLoad = {
        userId: chatId,
      };
      let params = {
        url: 'ref-users-api',
        method: 'post',
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if (response) {
        setRefUsers(response.data);
      }
    } catch (error) {
      console.log('getRefUsers sharing:', error);
    }
  };
  return (
    <div className="friends-zone goat-full-box-02 min-vh-100 d-flex justify-content-center flex-column align-items-center">
      <div className="container">
        <div className="row whl-goat-combine-hands">
          <div className="col-lg-12">
            <div className="">
              <div className="text-center">
                <img alt="" className="goat-combine-hands" src={Handshake} />
              </div>
              <h1 className="text-center goat-combine-hands-ctn text-white">
                Earn $BANI by inviting friends
              </h1>

              <div className="d-flex justify-content-between flex-wrap mt-4">
                <button onClick={copyCode} className="goat-copy-link-btn">
                  <IoCopy className="me-3 goat-copy-btns" />
                  {bttnText}
                </button>
                <button onClick={handleShare} className="goat-copy-link-btn">
                  <IoShareSocial className="me-3" />
                  Share link
                </button>
              </div>
              {refUsers.length > 0 ? (
                <>
                  <p className=" fw-bold mb-auto mt-4" style={{ color: 'grey' }}>
                    {refUsers.length + ' friends'}
                  </p>
                </>
              ) : (
                <></>
              )}
              {refUsers.length &&
                refUsers.map((data) => {
                  const initial = data.username
                    ? data.username.charAt(0).toUpperCase()
                    : data.first_name.charAt(0).toUpperCase();
                  return (
                    <>
                      <div className="bg-dark goat-posts my-1">
                        <div className="d-flex justify-content-between align-items-center  px-2 py-3 goat-boxex-tab">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="initials-fallback">{initial}</div>
                            </div>
                            <div>
                              <p className="mb-auto">
                                <span className="goats-earn-01 fw-bold text-white spacing">
                                  {data.username ? data.username : data.first_name}
                                </span>
                                <br />
                                <span
                                  className=" fw-bold total-small-size-ctn"
                                  style={{ color: 'grey' }}
                                >
                                  +222 $BANI
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Fourthtab;
