import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
function Banivideos() {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to home page
    }, 20000);

    // Clean up timeout on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  const handleVideoEnd = () => {
    navigate('/'); // Redirect to home page
  };
  return (
    <div className="goat-full-box-02 min-vh-100">
      <div>
        {/* <p className='text-white text-center pt-3'>Banivideos</p> */}
        {/* <iframe
      className="videoPlayer"
      width="100%"
      height="auto"
      src={video}
      allow="autoplay; encrypted-media"
      allowFullScreen
      onLoad={handleVideoEnd}
    ></iframe> */}
      </div>
    </div>
  );
}

export default Banivideos;
